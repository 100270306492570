import * as React from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
// import MenuIcon from '@mui/icons-material/Menu';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
interface Link {
  name: string;
  uri?: string;
}

interface NavbarProps {
  links;
}

// https://www.amazon.com/gp/product/B09DDM4TWW/ref=ox_sc_act_title_6?smid=A2T8OQYECIAEOF&th=1&psc=1
// https://www.amazon.com/gp/product/B09F5D1625/ref=ox_sc_act_title_1?smid=A1SX5DRE3K6SAQ&th=1&psc=1
//https://www.amazon.com/stores/page/1B92C752-FE6D-453E-BA30-F4ADDECB77A5?ingress=2&visitId=59f4377e-f8d6-477d-be21-990709fa5674&ref_=ast_bln
// https://www.amazon.com/dp/B07Y2C8W5V/ref=emc_b_5_t?th=1&psc=1
export class MaterialNavbar extends React.Component {
  props: any;
  render() {
    let links = [
      {
        name: "HOME",
      },
      {
        name: "SOBRE MI",
      },
    ];
    let linksElements = links.map((link: Link, i: number) => (
      <Button variant="text" color="inherit" key={i}>
        {link.name}
      </Button>
    ));
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            News
          </Typography>
          <nav>{linksElements}</nav>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
    );
  }
}

export class NavbarMaterialTabs extends React.Component {
  props: any;
  render() {
    let links = [
      {
        name: "HOME",
      },
      {
        name: "SOBRE MI",
      },
    ];
    let linksElements = links.map((link: Link, i: number) => (
      <Button variant="text" color="inherit" key={i}>
        {link.name}
      </Button>
    ));
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            {/* <MenuIcon /> */}
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            News
          </Typography>
          <nav>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Item One" {...a11yProps(0)} />
              <Tab label="Item Two" {...a11yProps(1)} />
              <Tab label="Item Three" {...a11yProps(2)} />
            </Tabs>
          </nav>
          <Button color="inherit">Login</Button>
        </Toolbar>
      </AppBar>
    );
  }
}
